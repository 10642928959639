<!-- 关于Hyve：企业责任 -->
<template>
<div class="responsibility">
    <div class="content" v-if="config.c1&&config.c1.isShow">
        <div class="wCenter">
            <div v-if="config.c1&&config.c1.isShow">
                <p class="text" v-for="(item, index) in config.c1.descr.split('\n')" :key="index">{{item}}</p>
            </div>
        </div>
    </div>
    <div class="content" v-if="config.c2&&config.c2.isShow">
        <div class="wCenter">
            <p class="title">{{config.c2.title}}</p>
            <div v-if="config.c2&&config.c2.isShow">
                <p class="text" v-for="(item, index) in config.c2.descr.split('\n')" :key="index">{{item}}</p>
            </div>
        </div>
    </div>
    <div class="content" v-if="config.c3&&config.c3.isShow">
        <div class="wCenter">
            <p class="title">{{config.c3.title}}</p>
            <div v-if="config.c3&&config.c3.isShow">
                <p class="text" v-for="(item, index) in config.c3.descr.split('\n')" :key="index">{{item}}</p>
            </div>
        </div>
    </div>
    <div class="content" v-if="config.c4&&config.c4.isShow">
        <div class="wCenter">
            <p class="title">{{config.c4.title}}</p>
            <div v-if="config.c4&&config.c4.isShow">
                <p class="text" v-for="(item, index) in config.c4.descr.split('\n')" :key="index">{{item}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getPageConfigByCode,
} from '@/api/api'
export default {
    name: 'home',
    
    data() {
        return {
            config: {}
        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'responsibility/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.responsibility {
    .content {
        padding: 0.77rem 0;

        @media (max-width: 767px) {
            padding: 0.3rem 0;
        }

        .title {
            color: #333333;
            font-size: 0.3rem;
            font-weight: bold;
            margin-bottom: 0.5rem;

            @media (max-width: 767px) {
                margin-bottom: 0.3rem;
				font-size: 0.4rem;
            }
        }

        .text {
            color: #333333;
            font-size: 0.24rem;
            line-height: 2;
            margin-bottom: 0.2rem;
            text-align: justify;
			@media (max-width: 767px) {
			     font-size: 0.38rem;
			}
        }
    }

    .content:nth-child(odd) {
        background: #eeeeee;
    }
}
</style>
